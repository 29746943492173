import Vue from 'vue'
import Vuex from 'vuex'
import _map from "lodash/map";


Vue.use(Vuex)

const successAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        text: '',
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setText(state, text) {
            state.text = text
        },
    },
    actions: {
        success({commit}, text = 'Erfolgreich gespeichert!') {
            commit('setText', text)
            commit('setActive', true)
            commit('errorAlert/setActive', false, {root: true})
        }
    }
}

const errorAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        serverErrors: [],
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setServerErrors(state, errors) {
            state.serverErrors = errors
        },
    },
    actions: {
        serverErrors({commit}, {e, message}) {
            if (e && 'graphQLErrors' in e) {
                let messages = e.graphQLErrors[0].extensions.validation;
                if (messages) {
                    commit('setServerErrors', _map(messages, (message) => {
                        return message[0]
                    }))
                } else {
                    commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
                }
            } else if (message) {
                commit('setServerErrors', [message])
            } else {
                commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
            }
            commit('setActive', true)
            commit('successAlert/setActive', false, {root: true})
        }
    }
}
export default new Vuex.Store({
    modules: {
        successAlert: successAlert,
        errorAlert: errorAlert,
    },
})
