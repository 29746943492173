import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


const messages = {
    en: {
        filter: {
            new: 'New',
            hidden: 'Hidden',
        },
        buttons: {
            reset: 'Reset',
            hide: 'Hide',
            login: 'Login',
        },
        general: {
            time: ''
        },
        status: {
            NEW: 'New',
            ANSWERED: 'Hidden'
        }
    },
    de: {
        filter: {
            new: 'Neue',
            hidden: 'Ausgeblendete',
        },
        buttons: {
            reset: 'Zurücksetzen',
            hide: 'Ausblenden',
            login: 'Anmelden',
        },
        general: {
            time: 'Uhr'
        },
        status: {
            NEW: 'Neu',
            ANSWERED: 'Ausgeblendet'
        }
    }
}

export default new VueI18n({
    fallbackLocale: 'de',
    locale: 'de',
    messages,
})