import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../views/Maintenance'),
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error'),
    },
    {
        path: '/:type/:id',
        component: () => import('../views/AudienceInteraction'),
        children: [
            {
                path: '',
                name: 'ToolList',
                component: () => import('../views/ToolList'),
            },
            {
                path: 'expert_screen',
                name: 'ExpertScreen',
                component: () => import('../views/ExpertScreen.vue'),
            },
            {
                path: 'poll_result',
                name: 'PollResult',
                component: () => import('../views/PollResult.vue'),
            },
            {
                path: 'chat_moderation',
                name: 'ChatModeration',
                component: () => import('../views/ChatModeration.vue'),
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
